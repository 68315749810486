import React, { Fragment, useContext } from "react";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import Appfooter from "../components/Appfooter";
import Popupchat from "../components/Popupchat";
import { useTranslation } from 'react-i18next';
import {
  useCookies,
  //  useContext
} from "react-cookie";
import LoginComponent from "../components/LoginComponent";
// import ProfileRecommender from "../components/ProfileRecommender";
import { ApiContext } from '../context/ApiContext'

const Settings = (props) => {
  const [cookies] = useCookies(["current-token"]);
  const { t } = useTranslation();
  const {profile } =
  useContext(ApiContext);

  return (
    <Fragment>
      <Header />
      <Leftnav />

      <div className="main-content bg-lightblue theme-dark-bg right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="middle-wrap">
              <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                <div className="card-body p-lg-5 p-4 w-100 border-0">
                  <div className="row">
                    <div className="col-lg-12">
                      {/* {!cookies["current-token"] ? (
                        <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3 me-3 bg-current">
                          <LoginComponent />
                        </div>
                      ) : (
                        <></>
                      )} */}
                      <h4 className="mb-4 font-xxl fw-700 mont-font mb-lg-5 mb-4 font-md-xs">
                      {t('Settings1')}
                      </h4>
                      <div className="nav-caption fw-600 font-xsss text-grey-500 mb-2">
                      {t('Settings2')}
                      </div>
                      <ul className="list-inline mb-4">
                        {
                          profile.id ? 
                          <li className="list-inline-item d-block border-bottom me-0">
                          <Link
                            to="/account"
                            className="pt-2 pb-2 d-flex align-items-center"
                          >
                            <i className="btn-round-md bg-primary-gradiant text-white feather-user font-md me-3"></i>{" "}
                            <h4 className="fw-600 font-xsss mb-0 mt-0">
                            {t('Settings3')}
                            </h4>
                            <i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                          </Link>

                        </li>
                        :
                        <></>

                        }

                        <li className="list-inline-item d-block border-bottom me-0">
                          <Link
                            to="/howto"
                            className="pt-2 pb-2 d-flex align-items-center"
                          >
                            <i className="btn-round-md bg-primary-gradiant text-white feather-chevrons-right font-md me-3"></i>{" "}
                            <h4 className="fw-600 font-xsss mb-0 mt-0">
                            {t('Settings4')}
                            </h4>
                            <i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                          </Link>

                        </li>
                        <li className="list-inline-item d-block border-bottom me-0">
                          <Link
                            to="/languagesetting"
                            className="pt-2 pb-2 d-flex align-items-center"
                          >
                            <i className="btn-round-md bg-primary-gradiant text-white feather-globe font-md me-3"></i>{" "}
                            <h4 className="fw-600 font-xsss mb-0 mt-0">
                            {t('Settings5')}
                            </h4>
                            <i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                          </Link>

                        </li>
                      </ul>
                      <div className="nav-caption fw-600 font-xsss text-grey-500 mb-2">
                      {t('Settings6')}
                      </div>
                      <ul className="list-inline mb-4">
                      <li className="list-inline-item d-block border-bottom me-0">
                          <Link
                            to="/adapplication"
                            className="pt-2 pb-2 d-flex align-items-center"
                          >
                            <i className="btn-round-md bg-blue-gradiant text-white feather-radio font-md me-3"></i>
                            <h4 className="fw-600 font-xsss mb-0 mt-0">
                            {t('Settings7')}
                            </h4>
                            <i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                          </Link>
                        </li>
                        <li className="list-inline-item d-block border-bottom me-0">
                          <Link
                            to="/Notations-SCTA"
                            className="pt-2 pb-2 d-flex align-items-center"
                          >
                            <i className="btn-round-md bg-blue-gradiant text-white feather-feather font-md me-3"></i>
                            <h4 className="fw-600 font-xsss mb-0 mt-0">
                            {t('Settings12')}
                            </h4>
                            <i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                          </Link>
                        </li>
                        {/* <li className="list-inline-item d-block border-bottom me-0">
                          <Link
                            to="/bankaccount"
                            className="pt-2 pb-2 d-flex align-items-center"
                          >
                            <i className="btn-round-md bg-gold-gradiant text-white feather-dollar-sign font-md me-3"></i>
                            <h4 className="fw-600 font-xsss mb-0 mt-0">
                            {t('Settings8')}
                            </h4>
                            <i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                          </Link>
                        </li> */}
                      </ul>

                      {/* <div className="nav-caption fw-600 font-xsss text-grey-500 mb-2">アカウント</div>
                                            <ul className="list-inline mb-4">
                                                <li className="list-inline-item d-block border-bottom me-0"><Link to="/payment" className="pt-2 pb-2 d-flex align-items-center"><i className="btn-round-md bg-mini-gradiant text-white feather-credit-card font-md me-3"></i> <h4 className="fw-600 font-xsss mb-0 mt-0">登録カード</h4><i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i></Link>
                                                </li>
                                                <li className="list-inline-item d-block  me-0"><Link to="/password" className="pt-2 pb-2 d-flex align-items-center"><i className="btn-round-md bg-blue-gradiant text-white feather-inbox font-md me-3"></i> <h4 className="fw-600 font-xsss mb-0 mt-0">パスワード</h4><i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i></Link></li>
                                                
                                            </ul> */}

                      {
                        profile.id ?
                        <div className="nav-caption fw-600 font-xsss text-grey-500 mb-2">
                      {t('Settings9')}
                      </div>:
                      <></>

                      }

                      

                      {
                        profile.id ?
                        <ul className="list-inline">
                        <li className="list-inline-item d-block me-0 border-bottom">
                          
                          <Link
                            to="/logout"
                            className="pt-2 pb-2 d-flex align-items-center"
                          >
                            <i className="btn-round-md bg-red-gradiant text-white feather-log-out font-md me-3"></i>
                            <h4 className="fw-600 font-xsss mb-0 mt-0">
                            {t('Settings10')}
                            </h4>
                            <i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                          </Link>
                        </li>
                        <li className="list-inline-item d-block me-0 border-bottom">
                          
                          <Link
                            to="/deleteaccount"
                            className="pt-2 pb-2 d-flex align-items-center"
                          >
                            <i className="btn-round-md bg-red-gradiant text-white feather-lock font-md me-3"></i>
                            <h4 className="fw-600 font-xsss mb-0 mt-0">
                            {t('Settings11')}
                            </h4>
                            <i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                          </Link>
                        </li>
                        
                      </ul>:
                      <></>

                      }
                      
                    </div>
                  </div>
                  
                </div>
              </div>
              <div className="p-4 mb-3"></div>
            </div>
          </div>
        </div>
      </div>

      <Popupchat />
      <Appfooter />
    </Fragment>
  );
};

export default Settings;
