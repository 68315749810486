import React, { useReducer, useCallback, useState, useEffect, useRef } from "react";
import { useContext } from "react";
import { ApiContext } from "../context/ApiContext";
import getCroppedImg from "./getCroppedImg";
import { Button, Slider } from "@material-ui/core";
import clsx from 'clsx';
import "./styles.css";
import axios from "axios";
import AttributeEach from "./AttributeEach";
import AttributeEachforModal from "./AttributeEachforModal";
import i18n from '../i18n'; 
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { useHistory, useParams } from "react-router-dom";
import { useGoogleLogin } from '@react-oauth/google';
import { withCookies } from "react-cookie";
import CircularProgress from "@material-ui/core/CircularProgress";
import { SnackbarContext } from "../context/SnackbarContext";
import {
    START_FETCH,
    FETCH_SUCCESS,
    ERROR_CATCHED,
    INPUT_EDIT_LOG,
    INPUT_EDIT_REG,
    TOGGLE_MODE,
} from "./actionTypes"
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { useTranslation } from 'react-i18next';

const initialState = {
    isLoading: false,
    isLoginView: true,
    error: "",
    credentialsLog: {
        username: "",
        password: "",
        confirmPassword: "",
    },
    credentialsReg: {
        email: "",
        password: "",
    },
};

const loginReducer = (state, action) => {
    switch (action.type) {
        case START_FETCH: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case FETCH_SUCCESS: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case ERROR_CATCHED: {
            return {
                ...state,
                error: action.payload ? action.payload : "Email or password is not correct!",
                isLoading: false,
            };
        }
        case INPUT_EDIT_LOG: {
            return {
                ...state,
                credentialsLog: {
                    ...state.credentialsLog,
                    [action.inputName]: action.payload,
                },
                error: "",
            };
        }
        case INPUT_EDIT_REG: {
            return {
                ...state,
                credentialsReg: {
                    ...state.credentialsReg,
                    [action.inputName]: action.payload,
                },
                error: "",
            };
        }
        case TOGGLE_MODE: {
            return {
                ...state,
                isLoginView: !state.isLoginView,
            };
        }
        default:
            return state;
    }
};




const useStyles = makeStyles((theme) => {
    return {
      PostButton: {
        backgroundColor: green[400],
        "&:hover": {
          backgroundColor: green[200],
        }
      },
      PostButtonContainer: {
        position: "fixed",
        width: "100%",
        zIndex: "999",
        left: "350px",
        bottom: "70px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        [theme.breakpoints.down('xs')]: {  // メディアクエリ
          left: '150px',
        },
      },
      cssloadLoader: {
        position: 'relative',  // 'absolute'から変更
        width: '30px',
        height: '30px',
        margin: '0 10px', // これにより左右の間隔を設定し、上下の間隔を0にします。
        perspective: '780px',
        left: '2px',
      },
      cssloadInner: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
        borderRadius: '50%',
        '&.cssloadOne': {
          left: '0%',
          top: '0%',
          animation: '$cssloadRotateOne 4s linear infinite',
          borderBottom: '3px solid #79ac78',
        },
        '&.cssloadTwo': {
          right: '0%',
          top: '0%',
          animation: '$cssloadRotateTwo 4s linear infinite',
          borderRight: '3px solid #79ac78',
        },
        '&.cssloadThree': {
          right: '0%',
          bottom: '0%',
          animation: '$cssloadRotateThree 4s linear infinite',
          borderTop: '3px solid #79ac78',
        },
      },
      cssloadLoader2: {
        position: 'relative',  // 'absolute'から変更
        width: '80px',
        height: '80px',
        margin: '0 10px', // これにより左右の間隔を設定し、上下の間隔を0にします。
        perspective: '780px',
        left: '7px',
      },
      cssloadInner2: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
        borderRadius: '50%',
        '&.cssloadOne': {
          left: '0%',
          top: '0%',
          animation: '$cssloadRotateOne 4s linear infinite',
          borderBottom: '3px solid #fff0f5',
        },
        '&.cssloadTwo': {
          right: '0%',
          top: '0%',
          animation: '$cssloadRotateTwo 4s linear infinite',
          borderRight: '3px solid #fff0f5',
        },
        '&.cssloadThree': {
          right: '0%',
          bottom: '0%',
          animation: '$cssloadRotateThree 4s linear infinite',
          borderTop: '3px solid #fff0f5',
        },
      },
      '@keyframes cssloadRotateOne': {
        '0%': {
          transform: 'rotateX(35deg) rotateY(-45deg) rotateZ(0deg)',
        },
        '100%': {
          transform: 'rotateX(35deg) rotateY(-45deg) rotateZ(360deg)',
        },
      },
      '@keyframes cssloadRotateTwo': {
        '0%': {
          transform: 'rotateX(50deg) rotateY(10deg) rotateZ(0deg)',
        },
        '100%': {
          transform: 'rotateX(50deg) rotateY(10deg) rotateZ(360deg)',
        },
      },
      '@keyframes cssloadRotateThree': {
        '0%': {
          transform: 'rotateX(35deg) rotateY(55deg) rotateZ(0deg)',
        },
        '100%': {
          transform: 'rotateX(35deg) rotateY(55deg) rotateZ(360deg)',
        },
      },
      bodyStyle: {
        // background: 'linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab)',
        background: 'linear-gradient(-45deg,#79AC78,#B0D9B1,#477451)',
        backgroundSize: '400% 400%',
        animation: '$gradient 20s ease infinite',
        // height: '100vh'
      },
      "@keyframes gradient": {
        "0%": {
          backgroundPosition: '0% 50%'
        },
        "50%": {
          backgroundPosition: '100% 50%'
        },
        "100%": {
          backgroundPosition: '0% 50%'
        }
      },
      flexContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
      },
      line: {
        display: 'block',
        position: 'relative',
        '&::after': {
          background: 'linear-gradient(to right, #eee 0%, #eee 50%, #eee 100%)',
          content: '""',
          display: 'block',
          height: '2px',
          width: 0,
          transform: 'translateX(-50%)',
          transition: '2s cubic-bezier(0.22, 1, 0.36, 1)',
          position: 'absolute',
          bottom: 0,
          left: '50%',
        },
        '&.is-animated::after': {
          width: '100%',
        },
      },
      bg: {
        '& $bgWrap': {
          position: 'relative',
          display: 'inline-block',
          marginTop: '5px',
        },
        '&.is-animated $bgWrap::before': {
          animation: '$bgAnimation 2.6s cubic-bezier(0.22, 1, 0.36, 1) forwards',
          background: 'linear-gradient(to right, #79ac78 0%,#3b79cc 50%,#42d3ed 100%)',
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          transformOrigin: 'left center',
        },
        '& $bgWrap $inn': {
          color: '#fff',
          display: 'inline-block',
          fontSize: '13px',
          fontWeight: 'bold',
          padding: '2px 10px',
          position: 'relative',
          zIndex: 1,
        },
      },
      bgWrap: {}, // この空のスタイルは、ネストされたセレクタの参照用
      inn: {}, // この空のスタイルは、ネストされたセレクタの参照用
      '@keyframes bgAnimation': {
        '0%': {
          opacity: 0,
          transform: 'scaleX(0) translateX(-5%)',
        },
        '30%': {
          transform: 'scaleX(1) translateX(0)',
          opacity: 1,
        },
        '100%': {
          transform: 'scaleX(1) translateX(0)',
          opacity: 1,
        },
      },
      '@keyframes fadeInUp': {
        '0%': {
          opacity: 0,
          transform: 'translateY(20px)',
        },
        '100%': {
          opacity: 1,
          transform: 'translateY(0)',
        },
      },
      // アニメーションを適用するクラス
      fadeinUp: {
        animation: `$fadeInUp 0.7s ${theme.transitions.easing.easeInOut} forwards`,
      },
    
    
  
    };
  });
  



const HowToModal = (props) => {

    const { t } = useTranslation();
    const history = useHistory();
    const classes = useStyles();
    const [state, dispatch] = useReducer(loginReducer, initialState);
    const [user, setUser] = useState(null);

    const [animate, setAnimate] = useState(false);
    useEffect(() => {
        setAnimate(true);
      }, []); // 空の依存配列でコンポーネントのマウント時のみ実行

    const [page, setPage] = useState(1);

    const nextPage = () => {
        setPage((prevPage) => (prevPage === 4 ? 1 : prevPage + 1));
    };

    const previousPage = () => {
        setPage((prevPage) => (prevPage === 5 ? 1 : prevPage - 1));
    };

    // ページが変更されるたびにアニメーションをトリガー
    useEffect(() => {
        if (page > 1) {
            setAnimate(false);
            setTimeout(() => {
                setAnimate(true);
            }, 10);
        }
    }, [page]); // 依存配列にpageを追加

    const gLogin = useGoogleLogin({
        onSuccess: (codeResponse) => {
            dispatch({ type: START_FETCH });
            setUser(codeResponse);
        },
        onError: (error) => console.log(t('LoginComponent2'), error)
    });




    return (
        <Dialog
            className="animated"
            open={props.isVisible}
            onClose={props.handleClose}
            aria-labelledby="max-width-dialog-title"
            fullWidth={true}
            maxWidth="sm"
            PaperProps={{
                style: {
                  backgroundColor: 'transparent', 
                },
            }}

        >
              <DialogContent className={`${classes.bodyStyle} card d-block border-0 rounded-3 overflow-hidden pt-1 pb-1 pe-2 ps-2 shadow-xss alert-current mb-0 `}>
            
            {/* 1ページ目 */}
            {page === 1 && (
            <div>
              <div className={`${classes.line} is-animated  text-white-pink fw-600 text-center mb-2 mt-1 font-xss `}>{t('HowToModal1')}</div>
                    <img
                                src="assets/images/howto_1.png"
                                alt="avater"
                                className="rounded-3 img-fluid w-100"
                              />


                    <h2 className="fw-600 font-xssss  mt-2 ps-2 pe-2 text-white-pink mb-4">{t('HowToModal2')}</h2>

                    <h2 className="fw-500 font-xsss  mt-2 ps-2 pe-2 text-center text-white-pink mb-4">1/4</h2>

                    
            </div>
            )}

            

            {/* 2ページ目 */}
            {page === 2 && (
                        <div>
              <div className={`${classes.line} is-animated  text-white-pink fw-600 text-center mb-2 mt-1 font-xss `}>{t('HowToModal3')}</div>
                    <img
                                src="assets/images/howto_2.png"
                                alt="avater"
                                className="rounded-3 img-fluid w-100"
                              />


                    <h2 className="fw-600 font-xssss  mt-2 ps-2 pe-2 text-white-pink mb-4">{t('HowToModal4')}< br/> {t('HowToModal5')}</h2>
                    <h2 className="fw-500 font-xsss  mt-2 ps-2 pe-2 text-center text-white-pink mb-4">2/4</h2>

                    
            </div>
            )}

            {/* 3ページ目 */}
            {page === 3 && (
            <div>
              <div className={`${classes.line} is-animated  text-white-pink fw-600 text-center mb-2 mt-1 font-xss `}>{t('HowToModal6')}</div>
                    <img
                                src="assets/images/howto_3.png"
                                alt="avater"
                                className="rounded-3 img-fluid w-100"
                              />


                    <h2 className="fw-600 font-xssss mt-2 ps-2 pe-2 text-white-pink mb-4">{t('HowToModal7')}</h2>
                    <h2 className="fw-500 font-xsss  mt-2 ps-2 pe-2 text-center text-white-pink mb-4">3/4</h2>
                    

                    
            </div>
            )}

            {/* 4ページ目 */}
            {page === 4 && (
            <div>
              <div className={`${classes.line} is-animated  text-white-pink fw-600 text-center mb-2 mt-1 font-xss `}>{t('HowToModal8')}</div>
              <div className="text-center">
              <h2 className="fw-600 text-center font-xssss mt-3  text-white-pink">{t('HowToModal9')}</h2>

              <button className="form-control w-75  text-center style2-input text-current fw-600 bg-white border-1 p-0 border-0" type="button" onClick={() => gLogin()}>
                    <svg viewBox="0 0 48 48" style={{ height: '18px', marginRight: '8px', minWidth: '18px', width: '18px', position: 'relative', verticalAlign: 'middle' }}>
                        <g>
                            <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                            <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                            <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                            <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                            <path fill="none" d="M0 0h48v48H0z"></path>
                        </g>
                    </svg>
                    {t('HowToModal10')}
                </button>
                    {/* <button className="w-75 mt-2 form-control text-center style2-input text-current fw-600 bg-white border-1 p-0 border-0" onClick={() => history.push('/login')}>{t('HowToModal11')}</button> */}


                    <h2 className="fw-600 text-center font-xssss mt-3  text-white-pink">{t('HowToModal12')}</h2>

                    <button className="w-75 mt-0 mb-3 form-control text-center style2-input text-current fw-600 bg-white-pink border-1 p-0 border-0" onClick={() => history.push('/recommend')}>{t('HowToModal13')}</button>

                 </div>
                 <h2 className="fw-500 font-xsss  mt-2 ps-2 pe-2 text-center text-white-pink mb-4">4/4</h2>

                 

                 

                    

                    
            </div>
            )}

            {page !== 4 && (        
            <span className="position-absolute bottom-5 mb-2 ms-2 right-15 pointer" onClick={nextPage}><i className="btn-round-sm bg-primary-gradiant text-white font-sm feather-chevron-right"></i></span>
            )}
            {page !== 1  && (        
            <span className="position-absolute bottom-5 mb-2 ms-2 left-15 pointer" onClick={previousPage}><i className="btn-round-sm bg-primary-gradiant text-white font-sm feather-chevron-left"></i></span>
            )}


           

            </DialogContent>
        </Dialog >
    );
};

export default HowToModal;
