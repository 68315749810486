import React, { useEffect, useState, Fragment } from "react";
import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';
import { useContext } from 'react';
import { ApiContext } from '../context/ApiContext';
import { Link } from 'react-router-dom';
import { useCookies } from "react-cookie";
import LoginComponent from "../components/LoginComponent";
import FollowingPageListEach from "./FollowingPageListEach";
import { useTranslation } from 'react-i18next';
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import Createpurepost from "../components/CreatePurepost";
import { AddComment } from "@material-ui/icons";
import { SnackbarContext } from "../context/SnackbarContext";
import { Tooltip, Fab } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
    return {
        PostButton: {
            backgroundColor: green[400],
            "&:hover": {
                backgroundColor: green[200],
            }
        },
        PostButtonContainer: {
            position: "fixed",
            width: "100%",
            zIndex: "999",
            left: "600px",
            bottom: "70px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            [theme.breakpoints.down('xs')]: {
                left: '150px',
            },
        },
    };
});

const FollowingPageList = () => {
    const { profile, followingpage, getFollowingPage } = useContext(ApiContext);
    const [pages, setPages] = useState([]);
    const classes = useStyles();
    const { newSnack } = useContext(SnackbarContext);
    const [isOpen, setOpen] = useState(false);

    const openDialog = () => {
        setOpen(true);
    };

    const closeDialog = () => {
        setOpen(false);
    };

    useEffect(() => {
        getFollowingPage();
    }, []);

    useEffect(() => {
        if (followingpage.length > 0) {
            setPages(followingpage.map(dev => dev.pageId));
        }
    }, [followingpage]);

    const { t } = useTranslation();
    return (
        <Fragment>
            <Header />
            <Leftnav />
            <div className="main-content right-chat-active">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left ps-2 pe-2">
                        <div className="row">
                            <div className={classes.PostButtonContainer}>
                                <Tooltip title="Post Comment" placement="top-start">
                                    {profile.id ? (
                                        <Fab
                                            color="secondary"
                                            className={classes.PostButton}
                                            onClick={openDialog}
                                        >
                                            <AddComment />
                                        </Fab>
                                    ) : (
                                        <Fab
                                            color="secondary"
                                            className={classes.PostButton}
                                            onClick={() => newSnack("error", t('FollowingPageList5'))}
                                        >
                                            <AddComment />
                                        </Fab>
                                    )}
                                </Tooltip>
                            </div>
                            <div className="col-xl-12 mb-3">
                                <Createpurepost
                                    isVisible={isOpen}
                                    profileData={profile}
                                    handleClose={closeDialog}
                                />
                                <div className="card w-100 border-0 p-0 pt-10 mt-5 bg-white shadow-xss rounded-xxl">
                                    <div className="card-body d-block pt-1 pb-0 ps-2 pe-2">
                                    </div>
                                    <div className='card-body p-0 position-relative'>
                                        {profile.img ?
                                            <figure className='avatar position-absolute w100 z-index-1' style={{ top: '-40px', left: '30px' }}><img src={profile.img} alt="avatar" className='float-right p-1 bg-white rounded-circle w-100' /></figure> :
                                            <figure className='avatar position-absolute w100 z-index-1' style={{ top: '-40px', left: '30px' }}><img src="https://ow6r85j5w096clt.site/media/image/サラリーマン.jpeg" alt="avatar" className='float-right p-1 bg-white rounded-circle w-100' /></figure>}

                                            {profile.nickName?
                                            <h4 className="fw-700 font-sm mt-2 mb-lg-5 mb-4 pl-15">
                                            {profile.nickName}
                                            <span className="fw-500 font-xssss text-grey-500 mt-1 mb-3 d-block">
                                            {profile.created_on}{t('Userpage6')}
                                            </span>
                                            </h4>:
                                            <h4 className="fw-700 font-sm mt-2 mb-lg-5 mb-4 pl-15">
                                            {t('Userpage1')}
                                            <span className="fw-500 font-xssss text-grey-500 mt-1 mb-3 d-block">
                                            XXXX-XX-XX{t('Userpage6')}
                                            </span>
                                            </h4>
                                            }
                                        <div className="position-absolute bottom-8 mb-2 right-15">
                                            <Link to="/settings">
                                                <button className="pointer mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ls-3 d-inline-block rounded-xl font-xsssss fw-700 ls-lg text-grey-600 d-flex">
                                                    <i className="feather-settings text-grey-500 mt-0 font-lg"></i>
                                                </button>
                                            </Link>
                                        </div>
                                    </div>

                                    <div className='card-body d-block w-100 shadow-none mb-0 p-0 border-top-xs'>
                                        <ul className='nav nav-tabs h55 d-flex product-info-tab border-bottom-0 ps-4' id="pills-tab" role="tablist">
                                            <li className='list-inline-item me-5'><a className='fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block' href="/userpage" data-toggle="tab">{t('FollowingPageList1')}</a></li>
                                            <li className="active list-inline-item me-5"><a className="active fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block" href="/followingpagelist" data-toggle="tab">{t('FollowingPageList2')}</a></li>
                                            <li className="list-inline-item me-5"><a className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block" href="/likedposts" data-toggle="tab">{t('FollowingPageList3')}</a></li>
                                            <li className="list-inline-item me-5"><a className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block" href="/posteddata" data-toggle="tab">{t('FollowingPageList4')}</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-12 col-xxl-12 col-lg-12">
                                {!profile.id ?
                                    <div className='card w-100 shadow-xss rounded-xxl border-0 mb-3'>
                                        <LoginComponent />
                                    </div> :
                                    <></>
                                }
                            <div className="row ps-2 pe-2">
                                {pages.filter(zz => zz !== undefined && zz !== null).map(zz =>
                                    <FollowingPageListEach
                                        key={zz.id}
                                        pageData={zz} />
                                )}
                            </div>
                                <div className="p-4 mb-3">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Popupchat />
            <Appfooter />
        </Fragment>
    );
}

export default FollowingPageList;
