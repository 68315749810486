import React, {  Fragment, useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';
import { useContext } from 'react';
import { ApiContext } from '../context/ApiContext';
// import Profiledetail from '../components/Profiledetail';
// import UserpagePicture from "../components/UserpagePicture";
// import FollowEach from "../components/FollowEach";
import { useCookies } from "react-cookie";
import LoginComponent from "../components/LoginComponent";
// import ProfileRecommender from "../components/ProfileRecommender";
import PostedDataEach from "../components/PostedDataEach";
import { useTranslation } from 'react-i18next';

const PostedData = () => {
    const { t } = useTranslation();
    const { profile, 
        // monoposts, profiles, followinguser,
        getUploadedFilesForUser } =  useContext(
        ApiContext
    );
    const [cookies] = useCookies(["current-token"]);
    const [uploadedfiles, setUploadedFiles] = useState([]);

    // const listProfiles =
    //     profile.id &&
    //     mutualfollowinguser.map(prof =>
    //       <FollowEach
    //         key={prof.id}
    //         prof = {prof}
    //       />
    //     );

    useEffect(() => {
        const fetchUploadedFiles = async () => {
            if (profile.id) {
                const files = await getUploadedFilesForUser(profile.id);
                setUploadedFiles(files);
            }
        };
    
        fetchUploadedFiles();
    }, [profile.id]);


    return (
        <Fragment>
            <Header />
            <Leftnav />


            <div className="main-content right-chat-active">
            <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left ps-2 pe-2">
                <div className="row">
                <div className="col-xl-12 mb-3">
                    <div className="card w-100 border-0 p-0 pt-10 mt-5 bg-white shadow-xss rounded-xxl">
                    <div className="card-body d-block  pt-1 pb-0 ps-2 pe-2">
                                    {/* {profile.imgBackground ?
                                        <div className="row ps-2 pe-2">
                                            <div className="col-sm-12 p-1"><img src={profile.imgBackground} className="rounded-xxl w-100" alt="post" /></div>                                        
                                        </div>
                                        : <></>} */}
                                </div>
                                <div className='card-body p-0 position-relative'>
                                    { profile.img ?
                                    <figure className='avatar position-absolute w100 z-index-1' style={{top:'-40px',left:'30px'}}><img src={profile.img} alt="avater" className='float-right p-1 bg-white rounded-circle w-100'/></figure>:
                                    <figure className='avatar position-absolute w100 z-index-1' style={{top:'-40px',left:'30px'}}><img src="https://ow6r85j5w096clt.site/media/image/サラリーマン.jpeg" alt="avater" className='float-right p-1 bg-white rounded-circle w-100'/></figure>}
                                        {profile.nickName?
                                        <h4 className="fw-700 font-sm mt-2 mb-lg-5 mb-4 pl-15">
                                        {profile.nickName}
                                        <span className="fw-500 font-xssss text-grey-500 mt-1 mb-3 d-block">
                                        {profile.created_on}{t('Userpage6')}
                                        </span>
                                        </h4>:
                                        <h4 className="fw-700 font-sm mt-2 mb-lg-5 mb-4 pl-15">
                                        {t('Userpage1')}
                                        <span className="fw-500 font-xssss text-grey-500 mt-1 mb-3 d-block">
                                        XXXX-XX-XX{t('Userpage6')}
                                        </span>
                                        </h4>
                                        }

                                    <div className="position-absolute bottom-8 mb-2 right-15">
                                    <Link to="/settings">
                                        <button className="pointer mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ls-3 d-inline-block rounded-xl  font-xsssss fw-700 ls-lg text-grey-600 d-flex">
                                        <i className="feather-settings text-grey-500 mt-0  font-lg"></i>
                                        </button>
                                    </Link>
                                    </div>
                                </div>

                                <div className='card-body d-block w-100 shadow-none mb-0 p-0 border-top-xs'>
                                    <ul className='nav nav-tabs h55 d-flex product-info-tab border-bottom-0 ps-4' id="pills-tab" role="tablist" >
                                        <li className='list-inline-item me-5'><Link className='fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block' to="/userpage" data-toggle="tab">{t('PostedData1')}</Link></li>
                                        <li className="list-inline-item me-5"><Link className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block" to="/followingpagelist" data-toggle="tab">{t('PostedData2')}</Link></li>
                                        {/* <li className="list-inline-item me-5"><Link className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block" to="/following" data-toggle="tab">フォロー</Link></li>
                                        <li className="list-inline-item me-5"><Link className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block" to="/followed" data-toggle="tab">フォロワー</Link></li> */}
                                        <li className="list-inline-item me-5"><a className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block" href="/likedposts" data-toggle="tab">{t('PostedData3')}</a></li>
                                        <li className="active list-inline-item me-5"><Link className="active fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block" to="/posteddata" data-toggle="tab">{t('PostedData4')}</Link></li>

                                    </ul>

                                </div>

                             </div>
                            </div>
                            <div className="col-xl-4 col-xxl-3 col-lg-4 pe-3">
{/*      
                                <Profiledetail />
                                <UserpagePicture /> */}
                            </div>
                            <div className="col-xl-12 col-xxl-12 col-lg-12">
                                {!profile.id ?
                                <div className='card w-100 shadow-xss rounded-xxl border-0 mb-3'>
                                <LoginComponent />
                                </div>:
                                <></>
                                }
                                {/* {!profile.id ?
                                <div className='card w-100 shadow-xss rounded-xxl border-0 mb-3 bg-current'>
                                <ProfileRecommender />
                                </div>:
                                <></>
                                } */}
                                <div className="">
                                    {/* {listProfiles} */}
                                    {
                                    uploadedfiles.map((item, index) => {
                                    return(
                                    <PostedDataEach
                                        item={item}
                                        key={index}
                                    />)
                                    })
                                }
                                </div>

                                {/* <div className="card-body p-0 mb-3">
                                <div className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3">
                                <div className="snippet mt-2 ms-auto me-auto" data-title=".dot-typing">
                                    <div className="stage">
                                    <div className="dot-typing"></div>
                                    </div>
                                </div>
                                </div>
                                </div>   */}
                                <div className="p-4 mb-3">
                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Popupchat />
            <Appfooter /> 
        </Fragment>
    );
}

export default PostedData;