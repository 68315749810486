import React, { useState, useContext, useEffect } from "react";
import { ApiContext } from "../context/ApiContext";
import { useHistory } from "react-router-dom";
import Commentview from "./Commentview";
import Createcomment from "../components/Createcomment";
import LinearProgress from "@mui/joy/LinearProgress";
import Lightbox from "react-image-lightbox";
import { Grid, Dialog, DialogContent, DialogContentText, DialogActions, DialogTitle, Button } from "@material-ui/core";
import { useTranslation } from 'react-i18next';
// import Button from "@mui/joy/Button";
import { SnackbarContext } from "../context/SnackbarContext";

const PostviewforPage = ({
  postData,
  profileData,
  reposting,
  repostingProfileData,
  commentedCounts, 
  repostedTimes, 
  likedTimes,  
}) => {
  const history = useHistory();
  const {
    profile,
    newUserIntPage,
    likePost,
    newUserIntUser,
    createRepost,
    deleteMonopost,
    DeletelikePost,
    getMonoCommentsForPost, 
    createMonoComment,
    checkPostInteractions,
    deleteRepost
  } = useContext(ApiContext);
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const [isDelModal, showDelModal] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [isReposted, setIsReposted] = useState(false);
  const [repostid, setRepostid] = useState();
  const [comments, setComments] = useState([]);
  const [currentLikedTimes, setCurrentLikedTimes] = useState(likedTimes);
  const [currentRepostedTimes, setCurrentRepostedTimes] = useState(repostedTimes);
  const [currentCommentedCounts, setCurrentCommentedCounts] = useState(commentedCounts);
  const [isCommented, setIsCommented] = useState(false); // コメント状態を追跡するために追加
  const { newSnack } = useContext(SnackbarContext);


  useEffect(() => {
    if (postData.liked.find((item) => item === Number(profile.userProfile))) {
      setIsLiked(true);
    }
  }, [postData, profile]);

  const onDeleteMonoPost = () => {
    deleteMonopost(postData.id);
    showDelModal(false);
  };
  const dolikePost = async () => {
    if (!profile.id) {
      newSnack("error", t('Header14'));  // エラーメッセージを表示
      return;
    }
    await likePost(postData);
    setIsLiked(true);
    setCurrentLikedTimes(currentLikedTimes + 1);
  };

  const doDeletelikePost = async () => {
    if (!profile.id) {
      newSnack("error", t('Header14'));  // エラーメッセージを表示
      return;
    }
    await DeletelikePost(postData);
    setIsLiked(false);
    setCurrentLikedTimes(currentLikedTimes - 1);
  };
  const doRepost = async () => {
    if (!profile.id) {
      newSnack("error", t('Header14'));  // エラーメッセージを表示
      return;
    }
    await createRepost(postData);
    setIsReposted(true);
    setCurrentRepostedTimes(currentRepostedTimes + 1); // リポスト数を増やす
  };

  const handleDeleteRepost = async () => {
    if (!profile.id) {
      newSnack("error", t('Header14'));  // エラーメッセージを表示
      return;
    }
    try {
      await deleteRepost(postData.id);  // deleteRepost関数を呼び出し、リポストを削除
      setIsReposted(false);
      setCurrentRepostedTimes(currentRepostedTimes - 1);
    } catch (error) {
      newSnack("error", t('RepostDeleteError'));  // エラーメッセージを表示
    }
  };
  
  

  const handleNewComment = () => {
    setCurrentCommentedCounts(currentCommentedCounts + 1);
  };

  const addNewComment = (newComment) => {
    // userComment を userProfile に変換
    const normalizedComment = {
        ...newComment,
        userProfile: newComment.userComment,
    };
    delete normalizedComment.userComment;

    setComments((prevComments) => [...prevComments, normalizedComment]); // 新しいコメントを配列の最後に追加
    setCurrentCommentedCounts(currentCommentedCounts + 1); // コメント数を更新
};



  

  const toSpecificUserPage = () => {
    if (profile.userProfile === profileData.userProfile) {
      history.push("/userpage");
    } else {
      history.push(`/user/${profileData.userProfile}`);
    }
  };

  const toSpecificUserPageRepost = () => {
    if (profile.userProfile === repostingProfileData.userProfile) {
      history.push("/userpage");
    } else {
      const createdIntData = new FormData();
      createdIntData.append("intUserId", repostingProfileData.userProfile);
      newUserIntUser(createdIntData);
      history.push("/user");
    }
  };



  const showcomment = async () => {
    setIsOpen(!isOpen);
    if (!isOpen && comments.length === 0) {
        const { comments_with_profiles } = await getMonoCommentsForPost(postData.id);
        setComments(comments_with_profiles);

    }
};


  const toMonopage = () => {
    newUserIntPage(postData.reviewTo, profile.userProfile);
    history.push(`/mono/${postData.reviewTo}`);
  };

  const handleCommentDeleted = (deletedCommentId) => {
    setComments((prevComments) =>
      prevComments.filter((comment) => comment.id !== deletedCommentId)
    );
    setCurrentCommentedCounts(currentCommentedCounts - 1);
  };

  useEffect(() => {
    const checkInteractions = async () => {
      if (profile.id && postData.id) {
        const interactions = await checkPostInteractions(postData.id);
        setIsLiked(interactions.is_liked);
        setIsReposted(interactions.is_reposted);
        setIsCommented(interactions.is_commented);  // コメント状態をセット
      }
    };

    checkInteractions();
  }, [postData, profile]);



  return (
    postData && profileData ?
      <div className="card w-100 shadow-xss rounded-xxl border-light-md ps-3 pe-3 pt-3 pb-2 mb-1">
        <Dialog open={isDelModal} onClose={() => showDelModal(false)} aria-labelledby="responsive-dialog-title">
          <DialogContent className="mycard card">
            <DialogContentText className="mont-font fw-400 font-xss mb-2 text-dark">
              {t('PostviewforPage1')}
            </DialogContentText>
          </DialogContent>
          <DialogActions className="bg-current mycard">
            <Button autoFocus onClick={() => onDeleteMonoPost()} color="" component="label" className="text-white">
              {t('PostviewforPage2')}
            </Button>
            <Button onClick={() => showDelModal(false)} color="" component="label" className="text-white">
              {t('PostviewforPage3')}
            </Button>
          </DialogActions>
        </Dialog>


      {!reposting ? (
        <div className="relative">
          <div className="card-body ps-1 pe-1 pt-0 pb-0 d-flex relative">
            <div>
              <div className="card-body p-0 d-flex">
                {profileData.img ? (
                  <figure
                    className="avatar me-3 pointer"
                    onClick={() => toSpecificUserPage()}
                  >
                    {profileData.img ?
                    <img
                      src={process.env.REACT_APP_API_URL + profileData.img}
                      alt="avater"
                      className="shadow-sm rounded-circle w40"
                    />:
                    <img
                      src="https://ow6r85j5w096clt.site/media/image/サラリーマン.jpeg"
                      alt="avater"
                      className="shadow-sm rounded-circle w40"
                    />

                  }
                  </figure>
                ) : (
                  <figure className="avatar me-3">
                    <img
                      src= "https://ow6r85j5w096clt.site/media/image/サラリーマン.jpeg"
                      alt="avater"
                      className="shadow-sm rounded-circle w45"
                    />
                  </figure>
                )}
                {profileData ? (
                  <h4 className="fw-700 text-grey-900 font-xssss mt-1">
                    {" "}
                    {profileData.nickName}{" "}
                    <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                      {" "}
                      {postData.created_on}
                    </span>
                  </h4>
                ) : (
                  <h4 className="fw-700 text-grey-900 font-xssss mt-1">
                    {" "}
                    Nick Name
                    <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                      {" "}
                      {postData.created_on}
                    </span>
                  </h4>
                )}
              </div>

              <div className="card-body p-0 me-lg-5">
                <p className="fw-600 text-grey-600 lh-26 font-xssss w-100 mb-2" style={{ whiteSpace: "pre-line" }}>
                  {postData.text}
                </p>
              </div>
            </div>
          </div>

          <div className="card-body d-block ps-1 pe-1 pt-0 pb-0">
            {postData.img ? (
              <div className="row ps-2 pe-2">
                <div className="col-sm-12 p-1">
                  {/* <img src={postData.img} className="rounded-3 w-100" alt="post" /> */}
                  {isOpenModal && (
                    <Lightbox
                      mainSrc={postData.img.slice(0,4)==="http" ? postData.img :process.env.REACT_APP_API_URL + postData.img }
                      onCloseRequest={() => setIsOpenModal(false)}
                    />
                  )}
                  <div onClick={() => setIsOpenModal(true)}>
                    <a>
                      <img
                        alt="post"
                        src={`${postData.img.slice(0,4)==="http" ? postData.img :process.env.REACT_APP_API_URL + postData.img }`}
                        className="img-fluid rounded-3 w-100"
                      />
                    </a>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="card-body p-0 mb-2">
                <div className="row">
                  <div className="col-12">
                    <h2 className="font-xsssss  lh-1 mt-2 text-grey-600 fw-500">
                    {t('PostviewforPage4')}{postData.rating}%
                    </h2>
                  </div>
                </div>
                <LinearProgress
                  color="info"
                  determinate
                  size="sm"
                  value={postData.rating}
                  variant="soft"
                />
              </div>


          <div className="card-body d-flex p-0 mt-1">
          {
              !isLiked ?
              <div
              className="emoji-bttn pointer d-flex align-items-center fw-600 text-grey-600 text-dark lh-26 font-xsssss me-2"
              onClick={() => dolikePost()}
            >
              <i className="feather-thumbs-up text-dark text-grey-600 btn-round-sm font-sm"></i>{" "}
              {currentLikedTimes} Liked
            </div>
            :
            <div
              className="emoji-bttn pointer d-flex align-items-center fw-600 text-current lh-26 font-xsssss me-2"
              onClick={() => doDeletelikePost()}
            >
              <i className="feather-thumbs-up text-current btn-round-sm font-sm"></i>{" "}
              {currentLikedTimes} Liked
            </div>

            }

            {!isReposted ?
            <a
            className="pointer d-flex align-items-center fw-600 text-grey-600 text-dark lh-26 font-xsssss"
            onClick={() => doRepost()}
          >
            <i className="feather-repeat text-dark text-grey-600 btn-round-sm font-sm"></i>
            <span className="d-none-xss">
            {currentRepostedTimes} Reposted
            </span>
          </a>:
          <a
          className="d-flex pointer align-items-center fw-600 text-current lh-26 font-xsssss"
          onClick={() => handleDeleteRepost()}
        >
          <i className="feather-repeat text-current btn-round-sm font-sm"></i>
          <span className="d-none-xss">
            {currentRepostedTimes} Reposted
          </span>
        </a>
            }

            

        {!isCommented ?
                <a
                className="pointer d-flex align-items-center fw-600 text-grey-600 text-dark lh-26 font-xsssss"
                onClick={showcomment}
              >
                <i className="feather-message-circle text-dark text-grey-600 btn-round-sm font-sm"></i>
                <span className="d-none-xss">{currentCommentedCounts} Comments</span>
              </a>:
                <a
                className="d-flex pointer align-items-center fw-600 text-current lh-26 font-xsssss"
                onClick={showcomment}
              >
                <i className="feather-message-circle text-current btn-round-sm font-sm"></i>
                <span className="d-none-xss">{currentCommentedCounts} Comments</span>
              </a>
              
            }     
          </div>
          <div className="card-body d-block p-0">
        {isOpen ? (
            <>
                {comments.map((commentWithProfile, index) => (
                    <Commentview
                        key={index}
                        commentData={commentWithProfile}
                        profileData={commentWithProfile.userProfile}
                        onCommentDeleted={handleCommentDeleted}
                    />
                ))}
              <Createcomment commentTo={postData.id} profileData={profile} onCommentAdded={addNewComment} />

                <div
                className="right-0 bottom-0 pointer me-1 text-grey-600 text-dark lh-26 font-xsssss me-2"
                onClick={() => showcomment()}
                >
                    {t('PostviewforPage5')}
                </div>
            </>
        ) : (
            <></>
        )}
    </div>

          {profile.userProfile === profileData.userProfile ? (
            <div
              className="position-absolute right-0 top-5 pointer me-1"
              onClick={() => showDelModal(true)}
            >
              <i className="ti-close font-xssss btn-round-xs bg-current text-white"></i>
            </div>
          ) : (
            <></>
          )}
        </div>
      ) 
      
      
      
      
      : (
        <div className="relative">
          <div className="card-body ps-1 pe-1 pt-0 pb-0 d-flex">
            {profileData ? (
              <figure
                className="avatar me-3 pointer"
                onClick={() => toSpecificUserPage()}
              > 
               {profileData.img ?
               
                <img
                  src={process.env.REACT_APP_API_URL + profileData.img}
                  alt="avater"
                  className="shadow-sm rounded-circle w40"
                />

                :
                <img
                  src="https://ow6r85j5w096clt.site/media/image/サラリーマン.jpeg"
                  alt="avater"
                  className="shadow-sm rounded-circle w40"
                />

               }
              </figure>
            ) : (
              <figure className="avatar me-3">
                <img
                  src="https://ow6r85j5w096clt.site/media/image/サラリーマン.jpeg"
                  alt="avater"
                  className="shadow-sm rounded-circle w45"
                />
              </figure>
            )}
            {profileData ? (
              <h4 className="fw-700 text-grey-900 font-xssss mt-1">
                {" "}
                {profileData.nickName}{" "}
                <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                  {" "}
                  {postData.created_on}
                </span>
              </h4>
            ) : (
              <h4 className="fw-700 text-grey-900 font-xssss mt-1">
                {" "}
                Nick Name
                <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                  {" "}
                  {postData.created_on}
                </span>
              </h4>
            )}
          </div>

          <div className="card w-100 shadow-xss rounded-xxl ps-3 pe-3 pb-3 pt-0 mb-1 border-light-md">

          <div className="card-body d-flex  p-0">
            <div
              className="emoji-bttn pointer d-flex align-items-center fw-600 text-grey-400 text-dark lh-26 font-xsssss "
            >
              <i className="feather-repeat text-dark text-dark text-grey-400 btn-round-sm font-xsss"></i>{" "}
              Reposted
            </div>
          </div>

            <div className="card-body p-0 d-flex">
              <div>
                <div className="card-body p-0 d-flex">
                  {repostingProfileData.img ? (
                    <figure
                      className="avatar me-3 pointer"
                      onClick={() => toSpecificUserPageRepost()}
                    >
                      <img
                        src={repostingProfileData.img}
                        alt="avater"
                        className="shadow-sm rounded-circle w30"
                      />
                    </figure>
                  ) : (
                    <figure className="avatar me-3">
                      <img
                        src="https://ow6r85j5w096clt.site/media/image/サラリーマン.jpeg"
                        alt="avater"
                        className="shadow-sm rounded-circle w45"
                      />
                    </figure>
                  )}
                  {repostingProfileData ? (
                    <h4 className="fw-700 text-grey-600 font-xsssss mt-1">
                      {" "}
                      {repostingProfileData.nickName}{" "}
                      <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">
                        {" "}
                        {postData.created_on}
                      </span>
                    </h4>
                  ) : (
                    <h4 className="fw-700 text-grey-600 font-xsssss mt-1">
                      {" "}
                      Nick Name
                      <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">
                        {" "}
                        {postData.created_on}
                      </span>
                    </h4>
                  )}
                </div>

                <div className="card-body p-0 me-lg-5">
                  <p className="fw-600 text-grey-500 lh-26 font-xssss w-100 mb-2" style={{ whiteSpace: "pre-line" }}>
                    {postData.text}
                  </p>
                </div>
              </div>

              <div className="card-body p-0 mb-1 d-flex"></div>
            </div>

            <div className="card-body d-block p-0">
              {postData.img ? (
                <div className="row ps-2 pe-2">
                  <div className="col-sm-12 p-1">
                    <img
                      src={postData.img.slice(0,4)==="http" ? postData.img :process.env.REACT_APP_API_URL + postData.img }
                      className="rounded-3 w-100"
                      alt="post"
                    />
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="card-body p-0 mb-2">
                  <div className="row">
                    <div className="col-12">
                      <h2 className="font-xsssss  lh-1 mt-2 text-grey-600 fw-500">
                      {t('PostviewforPage6')}{postData.rating}%
                      </h2>
                    </div>
                  </div>
                  <LinearProgress
                    color="info"
                    determinate
                    size="sm"
                    value={postData.rating}
                    variant="soft"
                  />
                </div>
          </div>

          <div className="card-body d-flex  p-0">
          {
              !isLiked ?
              <div
              className="emoji-bttn pointer d-flex align-items-center fw-600 text-grey-600 text-dark lh-26 font-xsssss me-2"
              onClick={() => dolikePost()}
            >
              <i className="feather-thumbs-up text-dark text-grey-600 btn-round-sm font-sm"></i>{" "}
              {currentLikedTimes} Liked
            </div>
            :
            <div
              className="emoji-bttn pointer d-flex align-items-center fw-600 text-current lh-26 font-xsssss me-2"
              onClick={() => doDeletelikePost()}
            >
              <i className="feather-thumbs-up text-current btn-round-sm font-sm"></i>{" "}
              {currentLikedTimes} Liked
            </div>

            }

{!isCommented ?
                <a
                className="pointer d-flex align-items-center fw-600 text-grey-600 text-dark lh-26 font-xsssss"
                onClick={showcomment}
              >
                <i className="feather-message-circle text-dark text-grey-600 btn-round-sm font-sm"></i>
                <span className="d-none-xss">{currentCommentedCounts} Comments</span>
              </a>:
                <a
                className="d-flex pointer align-items-center fw-600 text-current lh-26 font-xsssss"
                onClick={showcomment}
              >
                <i className="feather-message-circle text-current btn-round-sm font-sm"></i>
                <span className="d-none-xss">{currentCommentedCounts} Comments</span>
              </a>
              
            }
          </div>
          <div className="card-body d-block p-0">
        {isOpen ? (
            <>
                {comments.map((commentWithProfile, index) => (
                    <Commentview
                        key={index}
                        commentData={commentWithProfile}
                        profileData={commentWithProfile.userProfile}
                    />
                ))}
                {
                    profile.id ?
                    <Createcomment 
                        commentTo={postData.id} 
                        profileData={profile} 
                        onCommentAdded={addNewComment} 
                        onCommentDeleted={handleCommentDeleted}
                    />:
                    <></>
                }
                <div
                className="right-0 bottom-0 pointer me-1 text-grey-600 text-dark lh-26 font-xsssss me-2"
                onClick={() => showcomment()}
                >
                    {t('PostviewforPage5')}
                </div>
            </>
        ) : (
            <></>
        )}
    </div>
          {profile.userProfile === profileData.userProfile ? (
            <div
              className="position-absolute right-0 top-5 pointer me-1"
              onClick={() => {
                deleteMonopost(postData.id);
              }}
            >
              <i className="ti-close font-xssss btn-round-xs bg-current text-white"></i>
            </div>
          ) : (
            <></>
          )}
        </div>
      )}
    </div>
    :
    <></>
  );
};

export default PostviewforPage;
