import React, {
  Fragment, useContext, useState
  // useCallback, useState
} from "react";
import { ApiContext } from "../context/ApiContext";
import { Link } from "react-router-dom";
import {
  Button,
  // makeStyles
} from "@material-ui/core";
import { useCookies } from "react-cookie";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from 'react-accessible-accordion';
import { SnackbarContext } from "../context/SnackbarContext";
import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import Appfooter from "../components/Appfooter";
import { makeStyles } from "@material-ui/core/styles";
import AdsModal from "../components/AdsModal";
import { useTranslation } from 'react-i18next';

const NotationsSCTA = () => {
  const {
    profile,
    //  editedProfile, setEditedProfile, deleteProfile, setCover, coverBack, createProfile, editProfile
  } = useContext(ApiContext);

  const [cookies, setCookie, removeCookie] = useCookies(["current-token"]);
  const Logout = () => {
    localStorage.removeItem('current_token'); 
    window.location.replace("/login");
  };
  const { newSnack } = useContext(SnackbarContext);

  

  const useStyles = makeStyles((theme) => {
    return {
      root: {
        marginTop: 30,
        minWidth: "100%",
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        flexFlow: "column",
        "& .file-upload-container": {
          width: 500,
          marginTop: 10,
          "& .button": {
            backgroundColor: "#00A0FF",
            color: "white",
          },
        },
      },
      validationWrapper: {
        float: "right"
      }
    }
  })

  const [isShowAdsModal, setShowAdsModal] = useState(false);

  const handleAdsClick = () => {
    if (!profile.userProfile) {
      newSnack("error", t('NotationsSCTA1'));
      return;
    }
    setShowAdsModal(true);
  }
  const handleModalClose = () => {
    setShowAdsModal(false);
  }
  const { t } = useTranslation();

  return (
<Fragment>
    <Header />
    <Leftnav />
    <div className="main-content bg-lightblue theme-dark-bg right-chat-active">
    <AdsModal
        isVisible={isShowAdsModal}
        handleClose={handleModalClose}
      />
      <div className="middle-sidebar-bottom">
        <div className="middle-sidebar-left">
          <div className="middle-wrap">
            <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
              <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                <Link to="/settings" className="d-inline-block mt-2">
                  <i className="ti-arrow-left font-sm text-white"></i>
                </Link>
                <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">
                {t('NotationsSCTA2')}
                </h4>
              </div>

              <div className="card mt-2 w-100 border-0 p-0 rounded-3 overflow-hidden bg-image-contain bg-image-center " 
              style={{backgroundImage: `url("assets/images/background_advertisement.jpg")`}}>
                  <div className="card-body p-md-5 p-4 text-center" style={{backgroundColor:'rgba(71,120,79,0.8)'}}>
                      <h2 className="fw-700 display2-size text-white display2-md-size lh-2">{t('NotationsSCTA3')}</h2>
                      <p className="font-xsss ps-lg-5 pe-lg-5 lh-28 text-grey-200">{t('NotationsSCTA4')}</p>
                  </div>
              </div>
              <div className="card w-100 border-0 shadow-none bg-transparent mt-5">
                  <Accordion allowZeroExpanded className="accodion-style--1 accordion" preExpanded={'0'}>
                      <AccordionItem className="card shadow-xss">
                          <AccordionItemHeading className="card-header">
                              <AccordionItemButton>
                                  <h5 className="fw-600 pt-2 pb-2 mb-0">{t('NotationsSCTA5')}</h5>
                              </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel className="card-body">
                          <p>{t('NotationsSCTA6')}</p>
                          <div className="col-xl-8 col-xxl-9 col-lg-8 ">
                            <div className="pe-2 ">
                          
                              <img
                                src="assets/images/ad_2.png"
                                alt="avater"
                                className="rounded-3 img-fluid w-100"
                              />
                            </div>
                          </div>
                          </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem className="card shadow-xss">
                          <AccordionItemHeading className="card-header">
                              <AccordionItemButton>
                                  <h5 className="fw-600 pt-2 pb-2 mb-0">{t('NotationsSCTA7')}</h5>
                              </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel className="card-body">
                              <p>{t('NotationsSCTA8')}</p>
                              <div className="col-xl-8 col-xxl-9 col-lg-8 ">
                            <div className="pe-2 ">
                          
                              <img
                                src="assets/images/ad_5.png"
                                alt="avater"
                                className="rounded-3 img-fluid w-100"
                              />
                            </div>
                          </div>
                          </AccordionItemPanel>
                      </AccordionItem>

                      <AccordionItem className="card shadow-xss">
                          <AccordionItemHeading className="card-header">
                              <AccordionItemButton>
                                  <h5 className="fw-600 pt-2 pb-2 mb-0">{t('NotationsSCTA9')}</h5>
                              </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel className="card-body">
                          <p>{t('NotationsSCTA10')}</p>
                          <div className="col-xl-8 col-xxl-9 col-lg-8 ">
                            <div className="pe-2 ">
                          
                              <img
                                src="assets/images/ad_1.png"
                                alt="avater"
                                className="rounded-3 img-fluid w-100"
                              />
                            </div>
                          </div>
                          </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem className="card shadow-xss">
                          <AccordionItemHeading className="card-header">
                              <AccordionItemButton>
                                  <h5 className="fw-600 pt-2 pb-2 mb-0">{t('NotationsSCTA11')}</h5>
                              </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel className="card-body">
                              <p>{t('NotationsSCTA12')}</p>
                              <div className="col-xl-8 col-xxl-9 col-lg-8 ">
                            <div className="pe-2 ">
                          
                              <img
                                src="assets/images/ad_3.png"
                                alt="avater"
                                className="rounded-3 img-fluid w-100"
                              />
                            </div>
                          </div>
                          </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem className="card shadow-xss">
                          <AccordionItemHeading className="card-header">
                              <AccordionItemButton>
                                  <h5 className="fw-600 pt-2 pb-2 mb-0">{t('NotationsSCTA16')}</h5>
                              </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel className="card-body">
                              <p>{t('NotationsSCTA17')}</p>
                              <div className="col-xl-8 col-xxl-9 col-lg-8 ">
                            <div className="pe-2 ">
                          
                              <img
                                src="assets/images/ad_3.png"
                                alt="avater"
                                className="rounded-3 img-fluid w-100"
                              />
                            </div>
                          </div>
                          </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem className="card shadow-xss">
                          <AccordionItemHeading className="card-header">
                              <AccordionItemButton>
                                  <h5 className="fw-600 pt-2 pb-2 mb-0">{t('NotationsSCTA18')}</h5>
                              </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel className="card-body">
                              <p>{t('NotationsSCTA19')}</p>
                              <div className="col-xl-8 col-xxl-9 col-lg-8 ">
                            <div className="pe-2 ">
                          
                              <img
                                src="assets/images/ad_3.png"
                                alt="avater"
                                className="rounded-3 img-fluid w-100"
                              />
                            </div>
                          </div>
                          </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem className="card shadow-xss">
                          <AccordionItemHeading className="card-header">
                              <AccordionItemButton>
                                  <h5 className="fw-600 pt-2 pb-2 mb-0">{t('NotationsSCTA20')}</h5>
                              </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel className="card-body">
                              <p>{t('NotationsSCTA21')}</p>
                              <div className="col-xl-8 col-xxl-9 col-lg-8 ">
                            <div className="pe-2 ">
                          
                              <img
                                src="assets/images/ad_3.png"
                                alt="avater"
                                className="rounded-3 img-fluid w-100"
                              />
                            </div>
                          </div>
                          </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem className="card shadow-xss">
                          <AccordionItemHeading className="card-header">
                              <AccordionItemButton>
                                  <h5 className="fw-600 pt-2 pb-2 mb-0">{t('NotationsSCTA22')}</h5>
                              </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel className="card-body">
                              <p>{t('NotationsSCTA23')}</p>
                              <div className="col-xl-8 col-xxl-9 col-lg-8 ">
                            <div className="pe-2 ">
                          
                              <img
                                src="assets/images/ad_3.png"
                                alt="avater"
                                className="rounded-3 img-fluid w-100"
                              />
                            </div>
                          </div>
                          </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem className="card shadow-xss">
                          <AccordionItemHeading className="card-header">
                              <AccordionItemButton>
                                  <h5 className="fw-600 pt-2 pb-2 mb-0">{t('NotationsSCTA24')}</h5>
                              </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel className="card-body">
                              <p>{t('NotationsSCTA25')}</p>
                              <div className="col-xl-8 col-xxl-9 col-lg-8 ">
                            <div className="pe-2 ">
                          
                              <img
                                src="assets/images/ad_3.png"
                                alt="avater"
                                className="rounded-3 img-fluid w-100"
                              />
                            </div>
                          </div>
                          </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem className="card shadow-xss">
                          <AccordionItemHeading className="card-header">
                              <AccordionItemButton>
                                  <h5 className="fw-600 pt-2 pb-2 mb-0">{t('NotationsSCTA26')}</h5>
                              </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel className="card-body">
                              <p>{t('NotationsSCTA27')}</p>
                              <div className="col-xl-8 col-xxl-9 col-lg-8 ">
                            <div className="pe-2 ">
                          
                              <img
                                src="assets/images/ad_3.png"
                                alt="avater"
                                className="rounded-3 img-fluid w-100"
                              />
                            </div>
                          </div>
                          </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem className="card shadow-xss">
                          <AccordionItemHeading className="card-header">
                              <AccordionItemButton>
                                  <h5 className="fw-600 pt-2 pb-2 mb-0">{t('NotationsSCTA28')}</h5>
                              </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel className="card-body">
                              <p>{t('NotationsSCTA29')}</p>
                              <div className="col-xl-8 col-xxl-9 col-lg-8 ">
                            <div className="pe-2 ">
                          
                              <img
                                src="assets/images/ad_3.png"
                                alt="avater"
                                className="rounded-3 img-fluid w-100"
                              />
                            </div>
                          </div>
                          </AccordionItemPanel>
                      </AccordionItem>


                  </Accordion>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <Appfooter />
    </Fragment>
  );
};

export default NotationsSCTA;
