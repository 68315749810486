import React, { useContext } from 'react';
import { ApiContext } from '../context/ApiContext';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const Commentview = ({ commentData, profileData, onCommentDeleted }) => {
    const history = useHistory();
    const { profile, deleteComment } = useContext(ApiContext);
    const { t } = useTranslation();

    const handleDeleteComment = () => {
        deleteComment(commentData.id, () => {
            onCommentDeleted(commentData.id); // 削除後に呼び出す
        });
    };

    const toSpecificUserPage = () => {
        if (profileData && profileData.userProfile) {
            if (profile.userProfile === profileData.userProfile) {
                history.push("/userpage");
            } else {
                history.push(`/user/${profileData.userProfile}`);
            }
        }
    };

    if (!profileData) {
        return (
            <div className="card w-100 shadow-xss rounded-xxl border-light-md ps-4 pe-4 pt-3 mb-2 mt-2">
                <div className="card-body p-0 d-flex pointer">
                    <h4 className="fw-700 text-grey-900 font-xssss mt-1">
                        Loading...
                    </h4>
                </div>
            </div>
        );
    }

    return (
        <div className="card w-100 shadow-xss rounded-xxl border-light-md ps-4 pe-4 pt-3 mb-2 mt-2">
            {profile.userProfile === profileData.userProfile ? (
                <div
                    className="position-absolute right-0 top-5 pointer me-1"
                    onClick={handleDeleteComment}
                >
                    <i className="ti-close font-xssss btn-round-xs bg-current text-white"></i>
                </div>
            ) : null}

            <div className="card-body p-0 d-flex pointer" onClick={toSpecificUserPage}>
                {profileData.img ? (
                    <figure className="avatar me-3">
                        <img
                            src={profileData.img}
                            alt="avater"
                            className="shadow-sm rounded-circle w35 mt-1"
                        />
                    </figure>
                ) : (
                    <figure className="avatar me-3">
                        <img
                            src="https://ow6r85j5w096clt.site/media/image/サラリーマン.jpeg"
                            alt="avater"
                            className="shadow-sm rounded-circle w35 mt-1"
                        />
                    </figure>
                )}
                <h4 className="fw-700 text-grey-900 font-xssss mt-1">
                    {profileData.nickName}
                    <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                        {commentData.created_on}
                    </span>
                </h4>
            </div>
            <div className="card-body p-0 me-lg-5">
                <p className="fw-500 text-grey-600 lh-26 font-xssss w-100">{commentData.text}</p>
            </div>
        </div>
    );
};

export default Commentview;
