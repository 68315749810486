import React, { Fragment, useState, useEffect, useRef } from "react";
import { Link } from 'react-router-dom';
import { Tooltip, Fab } from "@material-ui/core";
import Slider from "react-slick";
import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import Appfooter from "../components/Appfooter";
import FriendRecommendslider from "./FriendRecommendslider";
import Postview from "./Postview";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import HomePicture from "../components/HomePicture";
import Requestleft from "../components/Requestleft";
import { useContext } from "react";
import { ApiContext } from "../context/ApiContext";
import FollowingPage from "./FollowingPage";
import Button from "@mui/joy/Button";
import { useCookies } from "react-cookie";
import LoginComponent from "../components/LoginComponent";
import AdvertisementView from "../components/AdvertisementView";
import { SnackbarContext } from "../context/SnackbarContext";
import NoFollowYet from "../components/NoFollowYet";
import { useTranslation } from 'react-i18next';
import Createpurepost from "../components/CreatePurepost";
import { AddComment } from "@material-ui/icons";
import Clockforpage from "../components/Clockforpage";
import HowToModal from "../components/HowToModal";
import DataModal from "../components/DataModal";

const useStyles = makeStyles((theme) => {
  return {
    PostButton: {
      backgroundColor: green[400],
      "&:hover": {
        backgroundColor: green[200],
      }
    },
    PostButtonContainer: {
      position: "fixed",
      width: "100%",
      zIndex: "999",
      left: "350px",
      bottom: "70px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      [theme.breakpoints.down('xs')]: { 
        left: '150px',
      },
    },
    loader: {
      background: '#fff0f5',
      animation: `$load1 3s infinite ease-in-out`,
      width: '1em',
      height: '2em',
      color: '#fff0f5',
      textIndent: '-9999em',
      left: '-30px' ,
      top: '5px' ,
      position: 'relative',
      fontSize: '11px',
      transform: 'translateZ(0)',
      animationDelay: '-2s',
      margin: '0 10px',
      "&:before": {
        position: 'absolute',
        top: 0,
        content: '""',
        left: '-1.5em',
        animationDelay: '-2s',
        background: '#fff0f5',
        animation: `$load1 2s infinite ease-in-out`,
        width: '1em',
        height: '1.5em',
      },
      "&:after": {
        position: 'absolute',
        top: 0,
        content: '""',
        left: '1.5em',
        background: '#fff0f5',
        animation: `$load1 2.5s infinite ease-in-out`,
        width: '1em',
        height: '1.5em',
      },
    },
    line: {
      display: 'block',
      position: 'relative',
      '&::after': {
        background: 'linear-gradient(to right,#79ac78 0%,#79ac78 50%,#79ac78 100%)',
        content: '""',
        display: 'block',
        height: '2px',
        width: 0,
        transform: 'translateX(-50%)',
        transition: '2s cubic-bezier(0.22, 1, 0.36, 1)',
        position: 'absolute',
        bottom: 0,
        left: '50%',
      },
      '&.is-animated::after': {
        width: '100%',
      },
    },
    "@keyframes load1": {
      '0%': {
        boxShadow: '0 0',
        height: '4em'
      },
      '40%': {
        boxShadow: '0 -2em',
        height: '5em'
      },
      '80%': {
        boxShadow: '0 0',
        height: '4em'
      },
      '100%': {
        boxShadow: '0 0',
        height: '4em'
      }
    },
    flexContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
    },
    DataButton: {
      background: 'linear-gradient(-45deg,#79AC78,#B0D9B1)',
      backgroundSize: '400% 400%',
      animation: '$gradient 10s ease infinite',
      },
    DataButtonContainer: {
        position: "fixed",
        width: "100%",
        zIndex: "999",
        left: "350px",
        bottom: "135px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        [theme.breakpoints.down('xs')]: {  // メディアクエリ
          left: '150px',
        },
    },
    cssloadLoader2: {
      position: 'relative',  // 'absolute'から変更
      width: '30px',
      height: '30px',
      margin: '0 10px', // これにより左右の間隔を設定し、上下の間隔を0にします。
      perspective: '780px',
      left: '1px',
    },
    cssloadInner: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      boxSizing: 'border-box',
      borderRadius: '50%',
      '&.cssloadOne': {
        left: '0%',
        top: '0%',
        animation: '$cssloadRotateOne 4s linear infinite',
        borderBottom: '3px solid #fff0f5',
      },
      '&.cssloadTwo': {
        right: '0%',
        top: '0%',
        animation: '$cssloadRotateTwo 4s linear infinite',
        borderRight: '3px solid #fff0f5',
      },
      '&.cssloadThree': {
        right: '0%',
        bottom: '0%',
        animation: '$cssloadRotateThree 4s linear infinite',
        borderTop: '3px solid #fff0f5',
      },
    },
    '@keyframes cssloadRotateOne': {
      '0%': {
        transform: 'rotateX(35deg) rotateY(-45deg) rotateZ(0deg)',
      },
      '100%': {
        transform: 'rotateX(35deg) rotateY(-45deg) rotateZ(360deg)',
      },
    },
    '@keyframes cssloadRotateTwo': {
      '0%': {
        transform: 'rotateX(50deg) rotateY(10deg) rotateZ(0deg)',
      },
      '100%': {
        transform: 'rotateX(50deg) rotateY(10deg) rotateZ(360deg)',
      },
    },
    '@keyframes cssloadRotateThree': {
      '0%': {
        transform: 'rotateX(35deg) rotateY(55deg) rotateZ(0deg)',
      },
      '100%': {
        transform: 'rotateX(35deg) rotateY(55deg) rotateZ(360deg)',
      },
    },
    bodyStyle: {
      background: 'linear-gradient(-45deg,#79AC78,#B0D9B1)',
      backgroundSize: '400% 400%',
      animation: '$gradient 10s ease infinite',
      // height: '100vh'
    },
    "@keyframes gradient": {
      "0%": {
        backgroundPosition: '0% 50%'
      },
      "50%": {
        backgroundPosition: '100% 50%'
      },
      "100%": {
        backgroundPosition: '0% 50%'
      }
    },
  };
});

const Home = (props) => {
  const {
    profiles,
    profile,
    postWithScroll,
    followingpage,
    getFollowingPage,
    ads,
    followinguser,
    getFollowingUser,
    getFollowedUser,
    getNewProfile,
    newProfiles,
    getFollowCheck,
    followCheck
  } = useContext(ApiContext);
  const { newSnack } = useContext(SnackbarContext);
  const { t } = useTranslation();
  const classes = useStyles();

  const [cookies] = useCookies(["current-token"]);
  const [count, setCount] = useState(0);
  const [postsbyscroll, setPostsbyscroll] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const loaderRef = useRef(null);

  const [isShowDataModal, setShowDataModal] = useState(false);

  const handleDataClick = () => {
    if (!profile.userProfile) {
      newSnack("error", t('Header14'));
      return;
    }
    setShowDataModal(true);
  }

  const handleDataModalClose = () => {
    setShowDataModal(false);
  }

  useEffect(() => {
    const fetchData = async () => {
      // if (profile.id) {  // ログインしている場合のみ実行
        await getFollowingPage();
      }
    // };
  
    fetchData();
  }, []); // profile.id の変更を監視
  

  useEffect(() => {
    if (profile.id) {
      getFollowedUser(profile.userProfile);
      getFollowingUser(profile.userProfile);
      getNewProfile();
    }
  }, [profile.id]); // profile.id の変更を監視
  
  

  const fetchData = async () => {
    setIsLoading(true);
    const result = await postWithScroll(count);
    if (result) {
      setPostsbyscroll(prevItems => [...prevItems, ...result]);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }

  const handleObserver = (entities, observer) => {
    const target = entities[0];
    if (target.isIntersecting && !isLoading) {
        setCount((prev) => prev + 1);
    }
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0
    };
    const observer = new IntersectionObserver(handleObserver, options);
    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }
    return () => {
      observer.disconnect();
    };
  }, []);


  useEffect(() => {
    // countの値に関係なくfetchDataを呼び出す
    fetchData();
  }, [count]);
  
  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  const [animate, setAnimate] = useState(false);
  useEffect(() => {
    setAnimate(true);
  }, []);

  const friendsettings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    centerMode: false,
    variableWidth: true,
  };

  const [listFriendRecommendSliderLists, setListFriendRecommendSliderLists] = useState([]);

  useEffect(() => {
    const fetchFollowChecksAndSetList = async () => {
      if (profile.id && newProfiles.length > 0) {
        const list = await Promise.all(newProfiles.map(async (user) => {
          const followCheck = await getFollowCheck(user.userProfile);
          return (
            <FriendRecommendslider
              key={user.id}
              profileeach={user}
              followCheck={followCheck}
              onFollowChange={handleFollowChange}  // ここで関数を渡していることを確認
            />
          );
        }));

        setListFriendRecommendSliderLists(list);
      }
    };
    fetchFollowChecksAndSetList();
  }, [profile.id, newProfiles]);

  const handleFollowChange = async () => {
    // 新しいプロファイルのデータを取得
    await getNewProfile(); 

    // newProfiles が更新された後にリストを作成
    const list = await Promise.all(newProfiles.map(async (user) => {
      const followCheck = await getFollowCheck(user.userProfile);
      return (
        <FriendRecommendslider
          key={user.id}
          profileeach={user}
          followCheck={followCheck}
          onFollowChange={handleFollowChange}  // ここで関数を渡していることを確認
        />
      );
    }));
    // リストを状態に設定
    setListFriendRecommendSliderLists(list);
};




  const getSpecificProfile = (id) => {
    return profiles.filter((item) => { return item.userProfile === id })[0];
  }

  const [isShowHowToModal, setShowHowToModal] = useState(false);
  const handleShowHowToModalClick = () => {
    setShowHowToModal(true)
  }
  const handleHowToModalClose = () => {
    setShowHowToModal(false);
  }
  
  useEffect(() => {
    const hasVisited = localStorage.getItem('hasVisited');
    if (!hasVisited) {
      setShowHowToModal(true);
      localStorage.setItem('hasVisited', 'true');
    }
  }, []);

  

  return (
    <Fragment>
      <Header />
      <Leftnav />

      <div className="main-content right-chat-active">
      <HowToModal
        isVisible={isShowHowToModal}
        handleClose={handleHowToModalClose}
      />
      <DataModal
        isVisible={isShowDataModal}
        handleClose={handleDataModalClose}
        
      />
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className={ classes.PostButtonContainer }>
                <Tooltip title="Post Comment" placement="top-start">
                  {profile.id ? (
                    <Fab
                      color="secondary"
                      className={classes.PostButton}
                      onClick={openDialog}
                    >
                      <AddComment />
                    </Fab>
                  ) : (
                    <Fab
                      color="secondary"
                      className={classes.PostButton}
                      onClick={()=>newSnack("error", t('Home3'))}
                    >
                      <AddComment />
                    </Fab>
                  )}
                </Tooltip>
              </div>
              <div className={ classes.DataButtonContainer }>
                <Tooltip title="Post Data" placement="top-start">
                  {profile.id ? (
                    <Fab
                      color="secondary"
                      className={classes.DataButton}
                      onClick={handleDataClick}
                    >
                    <div className={classes.flexContainer}>
                        <div className={classes.cssloadLoader2}>
                          <div className={`${classes.cssloadInner} cssloadOne`}></div>
                          <div className={`${classes.cssloadInner} cssloadTwo`}></div>
                          <div className={`${classes.cssloadInner} cssloadThree`}></div>
                        </div>
                    </div>
                    </Fab>
                  ) : (
                    <Fab
                      color="secondary"
                      className={classes.DataButton}
                      onClick={()=>newSnack("error", "投稿するにはログインしてください")}
                    >
                        <div className={classes.flexContainer}>
                        <div className={classes.cssloadLoader2}>
                          <div className={`${classes.cssloadInner} cssloadOne`}></div>
                          <div className={`${classes.cssloadInner} cssloadTwo`}></div>
                          <div className={`${classes.cssloadInner} cssloadThree`}></div>
                        </div>
                    </div>
                    </Fab>
                  )}
                </Tooltip>
              </div>
            <div className="row feed-body">
              <div className="col-xl-8 col-xxl-9 col-lg-8">
                {!profile.id ? (
                  <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
                    <LoginComponent />
                  </div>
                ) : (
                  <></>
                )}
                {profile.id && listFriendRecommendSliderLists.length > 0 && (
                  <Slider {...friendsettings}>
                    {listFriendRecommendSliderLists}
                  </Slider>
                )}


                <div className={`${classes.line}  ${animate ? "is-animated" : ""} font-xssss text-center text-current fw-600 mt-2 mb-2`}>{t('Home4')}</div>

                <span       
                    className="card pointer font-xsssss fw-700 pe-3 lh-32 text-uppercase rounded-3 ls-2 bg-green-gradiant text-white shadow-sm h125 border-0 w-100 mb-2 text-center"
                  >
                  <div className="d-flex ms-auto me-auto mt-2">
                    <div className={classes.flexContainer}>
                      <div className={classes.loader}>{t('Home5')}</div>
                    </div>
                    <div className="">
                      <h4 className="font-xss text-white-pink fw-600 mt-2 mb-1">
                        {t('Home6')}
                      </h4>
                      <h4 className="font-xssss text-white-pink fw-600 mt-0 mb-1">
                        {t('Home7')}
                      </h4>
                      <Clockforpage />
                    </div>
                  </div>
                </span>

                {profile.id && (
                  <div className={`${classes.line}  ${animate ? "is-animated" : ""} font-xssss text-center text-current fw-600 mt-2 mb-2`}>
                    {t('Home8')}
                  </div>
                )}


                { postsbyscroll ?
                  postsbyscroll.map((post, index) => (
                    <React.Fragment key={`post-${post.id}`}>  {/* ここでReact.Fragmentにkeyを追加 */}
                      <Postview
                        key={`post-${post.id}-${index}`}  // keyにはユニークな組み合わせを使用
                        postData={post}
                        profileData={post.userPostProfile}
                        reposting={post.reposting}
                        repostingProfileData={post.repostingProfile || null}
                        commentedCounts={post.commentedCounts} 
                        repostedTimes={post.repostedTimes}     
                        likedTimes={post.likedTimes}  
                        monopage = {post.reviewToPage}  
                      />
                      {index % 3 === 2 && parseInt(index / 3) < ads.length ? (
                        <AdvertisementView
                          key={`ad-${index}`}  // ユニークなkeyを設定
                          profile={getSpecificProfile(ads[parseInt(index / 3)].userId)}
                          item={ads[parseInt(index / 3)]}
                        />
                      ) : null}
                    </React.Fragment>
                  )) : null}



                <div ref={loaderRef} style={{ height: '20px', margin: '0 auto' }} className="mb-5"></div>

                {profile.id && followinguser.length === 0 ? <NoFollowYet /> : null}


                <Createpurepost
                  isVisible={isOpen}
                  profileData={profile}
                  handleClose={closeDialog}
                />

                {isLoading ? (
                  <div className="card-body p-0 mb-3">
                    <div className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3">
                      <div className="snippet mt-2 ms-auto me-auto" data-title=".dot-typing">
                        <div className="stage">
                          <div className="dot-typing"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>

              <div className="col-xl-4 col-xxl-3 col-lg-4 ps-lg-0">
              {profile.id && (
                <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3 p-0">
                  <div className="ard-body d-flex align-items-center ps-4 pe-4 pt-3 pb-3">
                    <h4 className="fw-700 mb-0 font-xssss text-grey-900">
                      {t('Home1')}
                    </h4>
                    <Link
                      to="/followingpagelist"
                      className="fw-600 ms-auto font-xssss text-primary"
                    >
                      {t('Home2')}
                    </Link>
                  </div>
                  {followingpage.slice(0, 3).map(zz => (
                    <FollowingPage
                      key={zz.pageId.id}  // ここでzz.pageId.idをkeyとして使用
                      monopage={zz}
                    />
                  ))}
                </div>
              )}


            {profile.id && <Requestleft />}
                {/* <HomePicture /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <Appfooter />
    </Fragment>
  );
};

export default Home;
