import React, { useEffect, useState, useContext } from "react";
import { ApiContext } from "../context/ApiContext";
import Lightbox from "react-image-lightbox";
import { useLocation, useParams } from "react-router-dom";
import { SnackbarContext } from "../context/SnackbarContext";
import copy from '../context/clipboard';
import { useTranslation } from 'react-i18next';

const SpecificUserpageEach = (props) => {
  const { profile, newRequestFriend, intuser, getFollowCheck, deleteFriendRequest, newUserIntUser } = useContext(ApiContext);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { pathname } = useLocation();
  const { newSnack } = useContext(SnackbarContext);
  const { userid } = useParams();
  const { t } = useTranslation();

  const [isFollowed, setIsFollowed] = useState(false); // フォロー状態を保持するstate

  useEffect(() => {
    newUserIntUser(userid);
  }, [userid]);

  useEffect(() => {
    const checkFollowStatus = async () => {
      if (profile.id) {  // ログインしている場合のみフォロー状態を確認
        const followCheck = await getFollowCheck(userid);
        setIsFollowed(followCheck.following);
      }
    };

    if (userid) {
      checkFollowStatus();
    }
  }, [userid, profile.id]);

  const createNewRequest = async () => {
    const uploadDataAsk = new FormData();
    uploadDataAsk.append("askTo", userid);
    uploadDataAsk.append("approved", false);
    await newRequestFriend(uploadDataAsk);
    setIsFollowed(true); // フォロー状態を更新
  };

  const handleUnfollow = async () => {
    await deleteFriendRequest(profile.userProfile, userid);
    setIsFollowed(false); // フォロー解除後の状態を更新
  };

  const sharePage = () => {
    copy(window.location.href);
    newSnack("info", t('SpecificUserpageEach1'));
  };

  return (
    <div className="card w-100 border-0 p-0 pt-10 mt-5 bg-white shadow-xss rounded-xxl">
      <div className="card-body d-block  pt-1 pb-0 ps-2 pe-2"></div>
      <div className="card-body p-0 position-relative">
        {intuser.id ? (
          <div>
            {isOpenModal && (
              <Lightbox
                mainSrc={process.env.REACT_APP_API_URL + intuser.img}
                onCloseRequest={() => setIsOpenModal(false)}
              />
            )}
            <div onClick={() => setIsOpenModal(true)}>
              <figure
                className="avatar position-absolute w100 z-index-1"
                style={{ top: "-40px", left: "30px" }}
              >
                <img
                  src={process.env.REACT_APP_API_URL + intuser.img}
                  alt="avater"
                  className="float-right p-1 bg-white rounded-circle w-100"
                />
              </figure>
            </div>
          </div>
        ) : (
          <figure
            className="avatar position-absolute w100 z-index-1"
            style={{ top: "-40px", left: "30px" }}
          >
            <img
              src="https://ow6r85j5w096clt.site/media/image/サラリーマン.jpeg"
              alt="avater"
              className="float-right p-1 bg-white rounded-circle w-100"
            />
          </figure>
        )}
        {intuser.nickName ? 
          <h4 className="fw-700 font-sm mt-2 mb-lg-5 mb-4 pl-15">
          {intuser.nickName}
          <span className="fw-500 font-xssss text-grey-500 mt-1 mb-3 d-block">
            {intuser.created_on}
          </span>
        </h4>:
        <h4 className="fw-700 font-sm mt-2 mb-lg-5 mb-4 pl-15">
        {t('SpecificUserpageEach2')}
        <span className="fw-500 font-xssss text-grey-500 mt-1 mb-3 d-block">
        {t('SpecificUserpageEach3')}
        </span>
        </h4>
        }
        
        <div className="position-absolute bottom-1 mb-0 mt-2 right-15">
          <button
            onClick={sharePage}
            className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-current font-xsssss fw-700 ls-lg text-white"
          >
            {t('SpecificUserpageEach4')}
          </button>
          {profile.id && (  // ログインしている場合のみフォローボタンを表示
            !isFollowed ? (
              <button
                className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-current font-xsssss fw-700 ls-lg text-white"
                onClick={createNewRequest}
              >
                {t('SpecificUserpageEach5')}
              </button>
            ) : (
              <button
                className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-grey font-xsssss fw-700 ls-lg text-white bg-"
                onClick={handleUnfollow}
              >
                {t('SpecificUserpageEach6')}
              </button>
            )
          )}
        </div>
      </div>

      <div className="card-body d-block w-100 shadow-none mb-0 p-0 border-top-xs">
        <ul
          className="nav nav-tabs h55 d-flex product-info-tab border-bottom-0 ps-4"
          id="pills-tab"
          role="tablist"
        >
          <li className="active list-inline-item me-5">
            <a
              className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block active"
              data-toggle="tab"
            >
             {t('SpecificUserpageEach7')}
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SpecificUserpageEach;
