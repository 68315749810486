import React from "react";
import { ApiContext } from "../context/ApiContext";
import  { useContext} from "react";
import { SnackbarContext } from "../context/SnackbarContext";
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";

const NotificationEach = ({ notiData, notiType }) => {

  const {
    profile,
    markNotificationAsRead
  } = useContext(ApiContext);

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  // Book詳細への遷移関数
  const toMonopage = async (pageId, notificationId) => {
    if (pageId) {
      await markNotificationAsRead(notificationId);
      history.push(`/mono/${pageId}`);
    } else {
      newSnack("error", t('Page not found'));
    }
  };

  // ユーザーBookへの遷移関数
  const toSpecificUserPage = async (userId, notificationId) => {
    await markNotificationAsRead(notificationId);
    if (userId === profile.userProfile) {
      history.push("/userpage");
    } else {
      history.push(`/user/${userId}`);
    }
  };
  const { t } = useTranslation();
  const { newSnack } = useContext(SnackbarContext);
  const history = useHistory();



  const renderNotificationContent = () => {
    switch (notiType) {
      case 'post-commented':
        return (
          <>
          <div onClick={() => toMonopage(notiData.page.id, notiData.id)}>
          <img src={notiData.page && notiData.page.img ? process.env.REACT_APP_API_URL + notiData.page.img : "assets/images/user.png"} alt="user" className="w40 position-absolute mt-1 left-0 shadow-sm rounded-3" />
            <h5 className="font-xsss text-grey-900 mb-1 mt-0 fw-700 d-block">
              {notiData.relatedUser.nickName} <span className="text-grey-400 font-xsssss fw-600 float-right mt-1"> {formatDate(notiData.created_on)}</span>
            </h5>
            <h6 className="text-grey-500 fw-500 font-xssss lh-4">{t('NotificationEach1')}</h6>
          </div></>
        );
      case 'post-liked':
        return (
          <>
          <div onClick={() => toMonopage(notiData.page.id, notiData.id)}>
          <img src={notiData.page && notiData.page.img ? process.env.REACT_APP_API_URL + notiData.page.img : "assets/images/user.png"} alt="user" className="w40 position-absolute mt-1 left-0 shadow-sm rounded-3" />
            <h5 className="font-xsss text-grey-900 mb-1 mt-0 fw-700 d-block">
              {notiData.relatedUser.nickName} <span className="text-grey-400 font-xsssss fw-600 float-right mt-1"> {formatDate(notiData.created_on)}</span>
            </h5>
            <h6 className="text-grey-500 fw-500 font-xssss lh-4">{t('NotificationEach2')}</h6>
          </div></>
        );
      case 'post-reposted':
        return (
          <>
          <div onClick={() => toMonopage(notiData.page.id, notiData.id)}>
          <img src={notiData.page && notiData.page.img ? process.env.REACT_APP_API_URL + notiData.page.img : "assets/images/user.png"} alt="user" className="w40 position-absolute mt-1 left-0 shadow-sm rounded-3" />
            <h5 className="font-xsss text-grey-900 mb-1 mt-0 fw-700 d-block">
              {notiData.relatedUser.nickName} <span className="text-grey-400 font-xsssss fw-600 float-right mt-1"> {formatDate(notiData.created_on)}</span>
            </h5>
            <h6 className="text-grey-500 fw-500 font-xssss lh-4">{t('NotificationEach3')}</h6>
          </div></>
        );
      case 'comment-commented':
        return (
          <>
          <div onClick={() => toMonopage(notiData.page.id, notiData.id)}>
          <img src={notiData.page && notiData.page.img ? process.env.REACT_APP_API_URL + notiData.page.img : "assets/images/user.png"} alt="user" className="w40 position-absolute mt-1 left-0 shadow-sm rounded-3" />
            <h5 className="font-xsss text-grey-900 mb-1 mt-0 fw-700 d-block">
              {notiData.relatedUser.nickName} <span className="text-grey-400 font-xsssss fw-600 float-right mt-1"> {formatDate(notiData.created_on)}</span>
            </h5>
            <h6 className="text-grey-500 fw-500 font-xssss lh-4">{t('NotificationEach4')}</h6>
          </div></>
        );
      case 'followingpage-commented':
        return (
          <>
          <div onClick={() => toMonopage(notiData.page.id, notiData.id)}>
          <img src={notiData.page && notiData.page.img ? process.env.REACT_APP_API_URL + notiData.page.img : "assets/images/user.png"} alt="user" className="w40 position-absolute mt-1 left-0 shadow-sm rounded-3" />
            <h5 className="font-xsss text-grey-900 mb-1 mt-0 fw-700 d-block">
              {notiData.page.title} <span className="text-grey-400 font-xsssss fw-600 float-right mt-1"> {formatDate(notiData.created_on)}</span>
            </h5>
            <h6 className="text-grey-500 fw-500 font-xssss lh-4">{t('NotificationEach5')}</h6>
          </div></>
        );
      case 'data-generated':
        return (
          <>
          <div onClick={() => toMonopage(notiData.page.id, notiData.id)}>
          <img src={notiData.page && notiData.page.img ? process.env.REACT_APP_API_URL + notiData.page.img : "assets/images/user.png"} alt="user" className="w40 position-absolute mt-1 left-0 shadow-sm rounded-3" />
            <h5 className="font-xsss text-grey-900 mb-1 mt-0 fw-700 d-block">
              {notiData.page.title} <span className="text-grey-400 font-xsssss fw-600 float-right mt-1"> {formatDate(notiData.created_on)}</span>
            </h5>
            <h6 className="text-grey-500 fw-500 font-xssss lh-4">{t('NotificationEach6')}</h6>
          </div></>
        );
      case 'data-book-owned':
        return (
          <>
          <div onClick={() => toMonopage(notiData.page.id, notiData.id)}>
          <img src={notiData.page && notiData.page.img ? process.env.REACT_APP_API_URL + notiData.page.img : "assets/images/user.png"} alt="user" className="w40 position-absolute mt-1 left-0 shadow-sm rounded-3" />
            <h5 className="font-xsss text-grey-900 mb-1 mt-0 fw-700 d-block">
              {notiData.page.title} <span className="text-grey-400 font-xsssss fw-600 float-right mt-1"> {formatDate(notiData.created_on)}</span>
            </h5>
            <h6 className="text-grey-500 fw-500 font-xssss lh-4">{t('NotificationEach7')}</h6>
          </div></>
        );
      case 'data-book-followed':
        return (
          <>
          <div onClick={() => toMonopage(notiData.page.id, notiData.id)}>
          <img src={notiData.page && notiData.page.img ? process.env.REACT_APP_API_URL + notiData.page.img : "assets/images/user.png"} alt="user" className="w40 position-absolute mt-1 left-0 shadow-sm rounded-3" />
            <h5 className="font-xsss text-grey-900 mb-1 mt-0 fw-700 d-block">
              {notiData.page.title} <span className="text-grey-400 font-xsssss fw-600 float-right mt-1"> {formatDate(notiData.created_on)}</span>
            </h5>
            <h6 className="text-grey-500 fw-500 font-xssss lh-4">{t('NotificationEach8')}</h6>
          </div></>
        );
      case 'user-followed':
        return (
          <>
          <div onClick={() => toSpecificUserPage(notiData.relatedUser.userProfile, notiData.id)}>
          <img src={notiData.relatedUser.img ? process.env.REACT_APP_API_URL + notiData.relatedUser.img : "assets/images/user.png"} alt="user" className="w40 position-absolute mt-1 left-0 shadow-sm rounded-circle" />
            <h5 className="font-xsss text-grey-900 mb-1 mt-0 fw-700 d-block">
              {notiData.relatedUser.nickName} <span className="text-grey-400 font-xsssss fw-600 float-right mt-1"> {formatDate(notiData.created_on)}</span>
            </h5>
            <h6 className="text-grey-500 fw-500 font-xssss lh-4">{t('NotificationEach9')}</h6>
          </div></>
        );
      default:
        return null;
    }
  };

  return (
    <div className="card bg-transparent-card w-100 border-0 ps-5 mb-3">
      
      {renderNotificationContent()}
    </div>
  );
};

export default NotificationEach;
